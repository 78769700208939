import Link from "next/link"
import { Alert } from "@ui/alert"
import { PageBlock } from "@ui/page-block"
import { Heading } from "@ui/heading"
import { Stack } from "@ui/stack"
import { InputField } from "@ui/input"
import { TopbarLayout } from "@layouts/topbar"
import { GetServerSideProps } from "next"
import { createServerClient } from "@graph/client"
import { useMutation } from "@apollo/client"
import { useRouter } from "next/router"
import { Field, Form } from "@graph/form"
import { Fieldset } from "@ui/fieldset"
import { Button } from "@ui/button"
import { Login, LoginVariables } from "@graph/types/Login"
import { AuthState } from "@graph/types/globalTypes"
import { AuthStateQuery } from "@graph/types/AuthStateQuery"
import AUTH_STATE_QUERY from "@graph/queries/auth-state.gql"
import LOGIN from "@graph/mutations/login.gql"
import { passwordResetPath, signupPath } from "@app/routes"
import { useCurrentUser } from "react-current-user"
import { required } from "validation"
import { ServerResponse } from "@server/response"
import { PageProps } from "@server/props"
import { trackLogin } from "analytics"
import { Card } from "@ui/card"
import { Row } from "@ui/row"

type LoginPageProps = PageProps<{
  redirectTo: string
}>

export default function LoginPage({ redirectTo }: LoginPageProps) {
  const router = useRouter()
  const [login] = useLogin()
  const [, setUser] = useCurrentUser()

  return (
    <TopbarLayout title="Login">
      <PageBlock>
        <Row>
          <Stack gap="6">
            <Form
              onSubmit={async ({ email, password }) => {
                if (!email || !password) return

                const { data } = await login({ variables: { email, password } })

                if (!data?.login) {
                  return { allFields: "Something went wrong. Please try again or contact support." }
                }

                if (data.login.errors) {
                  return data.login.errors
                }

                if (data.login.user) {
                  trackLogin()
                  setUser(data.login.user)
                  router.push(redirectTo)
                  return
                }
              }}
            >
              {({ handleSubmit, pristine, submitting, submitError }) => (
                <Card as="form" onSubmit={handleSubmit}>
                  <Heading as="h1" level="2">
                    Login to HeartPayroll
                  </Heading>
                  <Fieldset width={["100%", "page.half"]}>
                    <Field
                      id="email"
                      name="email"
                      label="Email"
                      required
                      autoFocus
                      component={InputField}
                      validate={required}
                    />
                    <Field
                      data-private
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      required
                      component={InputField}
                      validate={required}
                    />
                  </Fieldset>
                  <Button
                    id="submit-login"
                    fluid={[true, false]}
                    loading={submitting}
                    disabled={pristine || submitting}
                  >
                    Login
                  </Button>
                  {submitError && <Alert title="Login failed">{submitError}</Alert>}
                </Card>
              )}
            </Form>
            <Stack gap="3">
              <Link href={signupPath} passHref>
                <a>Sign up</a>
              </Link>
              <Link href={passwordResetPath} passHref>
                <a>Forgot your password?</a>
              </Link>
            </Stack>
          </Stack>
        </Row>
      </PageBlock>
    </TopbarLayout>
  )
}

const useLogin = () => {
  return useMutation<Login, LoginVariables>(LOGIN)
}

export const getServerSideProps: GetServerSideProps<LoginPageProps> = async (ctx) => {
  const redirectTo = (ctx.query["redirectTo"] as string) || "/"

  const client = createServerClient({ headers: ctx.req.headers })

  const { data } = await client.query<AuthStateQuery>({
    query: AUTH_STATE_QUERY,
  })

  if (data.authState === AuthState.logged_in) {
    return ServerResponse.redirect("/")
  }

  return {
    props: {
      redirectTo,
      ...data,
    },
  }
}
